
import { defineComponent, ref, computed } from '@vue/composition-api';
import { Field } from '../index';

interface AppProviders {
  google: boolean;
  apple: boolean;
  web: boolean;
}
interface OrganizerSetupOKR {
  instructions: string;
  providers: AppProviders;
}

interface AppSubmission {
  google: string;
  apple: string;
  web: string;
}
interface ParticipantOKR {
  submission: AppSubmission;
  description: string;
}

interface OKRField extends Field {
  value: ParticipantOKR;
  setup: OrganizerSetupOKR;
}

export default defineComponent({
  name: 'MDownloadApp',
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Download App';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-download-app';
          }
          return false;
        }
      );
      return Fdindx;
    });

    const fieldValue = computed<OKRField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const userTypeValue = computed(() => {
      if (
        _props.userType === 'organizer' &&
        _ctx.root.$route.name !== 'Monitor' &&
        !_props.isPreview
      ) {
        return 'organizer';
      }
      if (_props.isPreview) return 'participant';
      if (_props.isVisitor) return 'organizer';
      return 'participant';
    });

    const userIsEmployer = ref(userTypeValue.value === 'organizer');

    const participantData = computed({
      get: () => fieldValue.value.value,
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });

    const organizerData = computed({
      get: () => fieldValue.value.setup,
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });

    const providers = computed({
      get: () => {
        if (!organizerData.value.providers) {
          console.log('init organizerData', organizerData);
          organizerData.value.providers = {
            google: true,
            apple: true,
            web: true
          };
        }
        return organizerData.value.providers;
      },
      set: newVal => (organizerData.value.providers = newVal)
    });

    const submission = computed({
      get: () => {
        if (!participantData.value.submission) {
          console.log('init participantData', participantData);
          participantData.value.submission = {
            google: '',
            apple: '',
            web: ''
          };
        }
        return participantData.value.submission;
      },
      set: newVal => (participantData.value.submission = newVal)
    });

    const urlRegex =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/;

    return {
      userIsEmployer,
      participantData,
      organizerData,
      providers,
      submission,
      urlRegex
    };
  }
});
